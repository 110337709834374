$content-width: 1000px;
$base-line-height: 1.6;

@import "minima";

@font-face {
    font-family: Mono;
    font-style: normal;
    font-weight: 400;
    src:
        url('mono-1.0.3/web/woff2/JetBrainsMono-Regular.woff2') format('woff2'),
        url('mono-1.0.3/web/woff/JetBrainsMono-Regular.woff') format('woff');
}

@font-face {
    font-family: Mono;
    font-style: normal;
    font-weight: 700;
    src:
        url('mono-1.0.3/web/woff2/JetBrainsMono-Bold.woff2') format('woff2'),
        url('mono-1.0.3/web/woff/JetBrainsMono-Bold.woff') format('woff');
}

img {
    border: 1px solid #dee2e6;
}

code {
    font-family: 'Mono', monospace;
    font-size: 13px;
}

.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
}
